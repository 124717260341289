<template>
  <b-card>
    <div slot="header">
      <strong>Editar Empresa</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Empresas' }">Listar Empresas</router-link></p>
    <form v-on:submit.prevent="salvaGrupo">
      <div class="row">

        <div class="col-lg-4">
          <div class="form-group">
              <label name="mnemonico"><strong>Nome Fantasia</strong></label>
              <input type="text" class="form-control" v-model="grupo.grp_mnemonico" id="mnemonico" required>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="form-group">
              <label name="descricao"><strong>Empresa</strong></label>
              <input type="text" class="form-control" v-model="grupo.grp_descricao" id="descricao" required>
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col-lg-2">
          <b-button variant="warning" @click="resetarTudo()">Resetar conta</b-button>
        </div>

        <div class="col-lg-2">
          <div class="form-group">
              <label name="dataCriacao"><strong>Tipo</strong></label>
              <select class="form-control" v-model="grupo.gtipo_ID">
                <option value="2">Empresa</option>
              </select>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="dataCriacao"><strong>Plano</strong></label>
              <select class="form-control" v-model="grupo.grp_registros">
                <option value="">Ilimitado</option>
                <option value="50">até 50 produtos</option>
                <option value="100">até 100 produtos</option>
                <option value="200">até 200 produtos</option>
                <option value="500">até 500 produtos</option>
              </select>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="dataCriacao"><strong><abbr title="Deixe em branco para acesso indeterminado">Validade</abbr></strong></label>
              <b-form-input v-model="grupo.grp_data_validade" type="date"></b-form-input>
          </div>
        </div>

        <div class="col-lg-2">
          <div class="form-group">
              <label name="descricao"><strong>Ativo</strong></label><br>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': grupo.grp_ativo }"
                  @click="grupo.grp_ativo = true">Ativo</button>
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': !grupo.grp_ativo }"
                  @click="grupo.grp_ativo = false">Inativo</button>
              </div>
          </div>
        </div>

        

      </div>

      <button type="submit" class="btn btn-primary">Salvar</button>

    </form>
  </b-card>
</template>

<script>
export default {
  data () {
    return {
      grupo: {
        grp_mnemonico: '',
        grp_descricao: '',
        grp_ativo: 0,
        gtipo_ID: 2,
        grp_coord_areas: 1,
        grp_ID: 0
      }
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.grupo.grp_ID = this.$route.params.id
        this.carregarGrupo()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    carregarGrupo: function () {
      this.campos('gp_Grupo', 'grp_ID = ' + this.grupo.grp_ID).then(
        (response) => {
          this.grupo = response
          console.log(this.grupo)
        },
        (response) => {}
      )
    },
    salvaGrupo: function () {
      this.atualizar('gp_Grupo', this.grupo, this.grupo.grp_ID).then(
        (response) => {
          this.$router.push('/admin/empresas/listar')
        },
        (response) => {}
      )
    },
    resetarTudo: function () {
      this.$swal.fire({
        title: 'Tem certeza disso?',
        text: 'Esta ação limpa TODOS os dados dessa empresa (exceto contas de usuários auxiliares). Não há maneiras de desfazer essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK! Estou ciente disso.',
        cancelButtomText: 'Cancelar ação',
      }).then((result) => {
        if (result.value) {
          var cond = 'grp_ID = ' + this.grupo.grp_ID
          var prom = []
          prom.push(this.removerAdm('gp_Metadados', cond))
          prom.push(this.removerAdm('pro_Encargo_Item', cond))
          prom.push(this.removerAdm('pro_Encargo', cond))
          prom.push(this.removerAdm('pro_Produto_Maodeobra', cond))
          prom.push(this.removerAdm('pro_Produto_Material', cond))
          prom.push(this.removerAdm('pro_Material', cond))
          prom.push(this.removerAdm('pro_Material_Unidade', cond))
          prom.push(this.removerAdm('pro_Maodeobra', cond))
          prom.push(this.removerAdm('pro_Produto', cond))
          prom.push(this.removerAdm('pro_Produto_Linha', cond))
          Promise.all(prom).then(
            (a) => {
              this.$swal.fire(
                'Dados limpos.',
                'Todos os dados foram removidos.',
                'success'
              )
            },
            (a) => {}
          )
        }
      })
    }
  }
}
</script>

<style>

</style>
